<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-sm-4">
        <div class="panel border bg-white shadow rounded-lg">
          <div class="panel-heading text-center">
            <h3 class="pt-3 font-weight-bold">
              <img src="images/rw.png" /> <br />
              RW Messenger
            </h3>
            <hr />
          </div>
          <div class="panel-body px-3">
            <form @submit.stop.prevent="onSubmit">
              <div class="form-group">
                <div class="input-field">
                  <span class="fa fa-user p-2"></span>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    required
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-field">
                  <span class="fa fa-lock px-2"></span>
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    required
                    v-model="form.password"
                  />
                  <span class="btn bg-white text-muted">
                    <span class="fa fa-eye-slash"></span>
                  </span>
                </div>
              </div>
              <div class="form-inline">
                <input type="checkbox" name="remember" id="remember" />
                <label for="remember" class="text-muted">Remember me</label>
                <a href="#" id="forgot" class="font-weight-bold"
                  >Forgot password?</a
                >
              </div>
              <button type="submit" class="btn btn-primary btn-block mt-3">
                Login
              </button>
            </form>
          </div>
          <div class="mx-3 my-2 py-2 bordert mt-4">
            <div class="text-center py-3">
              <a class="px-2" @click="onSubmit">
                <img src="images/btn_google.png" alt="Google" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#3498db"
      loader="bars"
    ></loading>
  </div>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    loading
  },
  data() {
    return {
      form: {
        email: "addin@rw.com",
        password: "password"
      },
      isLoading: false
    };
  },
  methods: {
    onSubmit: function() {
      this.isLoading = !this.isLoading;
      localStorage.setItem("login", true);
      setTimeout(() => {
        this.isLoading = !this.isLoading;
        if (
          this.form.email !== "addin@rw.com" ||
          this.form.password !== "password"
        ) {
          this.$swal("Error", "Incorrect email or password.", "error");
        } else {
          this.$router.push("/staf");
        }
      }, 3000);
    }
  }
};
</script>

<style scoped>
.panel-heading img {
  width: 50px;
}
#forgot {
  min-width: 100px;
  margin-left: auto;
  text-decoration: none;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

.form-inline label {
  padding-left: 10px;
  margin: 0;
  cursor: pointer;
}

.input-field {
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  /*color: #4343ff*/
}

input[type="email"],
input[type="password"] {
  border: none;
  outline: none;
  box-shadow: none;
  width: 100%;
}

.fa-eye-slash.btn {
  border: none;
  outline: none;
  box-shadow: none;
}

.bordert {
  border-top: 1px solid #aaa;
  position: relative;
}

.bordert:after {
  content: "OR";
  position: absolute;
  top: -13px;
  left: 45%;
  background-color: #fff;
  padding: 0px 8px;
}
</style>
